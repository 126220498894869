<template>
  <div class="row">
    <div class="col-8">
      <el-upload action="someurl" multiple drag :http-request="uploadFile">
        <div class="h-100 d-flex flex-column align-items-center py-4">
          <UploadIcon width="3em" height="2em" class="text-primary" />
          <small>Drop file here or <em>click to upload</em></small>
        </div>
      </el-upload>
      <div class="text-nowrap">
        <Table
          v-loading="loading"
          :columns="columns"
          :data="priceListUpdates"
          show-index
          border
          rounded
          :cell-class="getCellClass"
          @row-click="selectedRowIndex = $event"
        >
          <template #cell-createdAt="{ rowData }">
            <div class="cell-with-subtext">
              {{ formatDate(rowData.createdAt) }}
              <small class="text-muted">
                {{ formatDayTime(rowData.createdAt) }}
              </small>
            </div>
          </template>
          <template #cell-filename="{ rowData }">
            <div class="text-truncate">
              {{ rowData.filePath && getFilename(rowData.filePath) }}
            </div>
          </template>
          <template #cell-createdBy="{ rowData: { createdBy } }">
            {{ createdBy && createdBy.profile.firstName + ' ' + createdBy.profile.lastName }}
          </template>
          <template #cell-priceListId="{ rowData: priceListUpdate }">
            <el-select
              :value="priceListUpdate.priceListId"
              @change="(priceListId) => onPriceListChange(priceListUpdate, priceListId)"
            >
              <el-option
                v-for="priceList in priceLists"
                :key="priceList.id"
                :value="priceList.id"
                :label="priceList.name"
              />
            </el-select>
          </template>
          <template #cell-recordAction="{ rowData }">
            <Button type="secondary" :disabled="!rowData.priceListId" @click="routeToUpdate(rowData.id)">{{
              $t('price.PriceListUpdates.recordDocument')
            }}</Button>
          </template>
        </Table>
      </div>
    </div>
    <div class="col">
      <PdfViewer v-if="currentFilePathUrl" :src="currentFilePathUrl" />
    </div>
  </div>
</template>

<script>
import { pick } from 'ramda';
import { computed, ref, getCurrentInstance } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';

import { options } from '@/locale/dateConfig';
import { Table, Button } from '@/modules/core/components';
import { PdfViewer } from '@/modules/core/components/PdfViewer';
import { UploadIcon } from '@/assets/icons';
import { useNotification } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import {
  usePriceListUpdates,
  CREATE_PRICE_LIST_UPDATE_MUTATION,
  UPDATE_PRICE_LIST_UPDATE_MUTATION,
  PRICE_LIST_UPDATE_FILE_UPLOAD_QUERY,
  PRICE_LIST_UPDATE_FILEPATH_QUERY,
} from '../compositions';

const getFilename = (path) => path.split('/').pop();

export default {
  components: { Table, Button, UploadIcon, PdfViewer },
  props: {
    priceLists: { type: Array, default: () => [] },
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { error } = useNotification();
    const { currentTenant } = useTenancy();
    const { priceListUpdates, refetch, loading } = usePriceListUpdates(
      () => ({
        recorded: false,
        businessId: currentTenant.value?.id,
      }),
      () => ({ enabled: Boolean(currentTenant.value?.id) })
    );
    const { mutate: createPriceListUpdate } = useMutation(CREATE_PRICE_LIST_UPDATE_MUTATION);
    const { mutate: updatePriceListUpdate } = useMutation(UPDATE_PRICE_LIST_UPDATE_MUTATION);
    const formatDate = (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, options.short);
    const formatDayTime = (ms) =>
      new Date(ms).toLocaleString(root.$i18n.locale, { weekday: 'short', hour: '2-digit', minute: '2-digit' });

    const selectedRowIndex = ref(null);
    const { result } = useQuery(
      PRICE_LIST_UPDATE_FILEPATH_QUERY,
      () => ({ id: priceListUpdates.value[selectedRowIndex.value]?.id }),
      () => ({ enabled: selectedRowIndex.value !== null })
    );
    const currentFilePathUrl = computed(() => {
      return result.value?.priceListUpdate.filePathUrl;
    });
    return {
      columns: [
        { key: 'createdAt', header: root.$t('price.PriceListUpdates.headers.createdAt') },
        { key: 'filename', header: root.$t('price.PriceListUpdates.headers.filename') },
        { key: 'createdBy', header: root.$t('price.PriceListUpdates.headers.createdBy') },
        { key: 'priceListId', header: root.$t('price.PriceListUpdates.headers.priceListSelect') },
        { key: 'recordAction' },
      ],
      priceListUpdates,
      loading,
      formatDate,
      formatDayTime,
      getFilename,
      currentFilePathUrl,
      selectedRowIndex,
      async uploadFile(request) {
        request.onError(); // prevent el-upload to show file list
        const uploadLoading = root.$loading();
        try {
          const {
            data: {
              priceListUpdateFileUpload: { url, filePath, fields },
            },
          } = await root.$apollo.query({
            query: PRICE_LIST_UPDATE_FILE_UPLOAD_QUERY,
            variables: {
              businessId: currentTenant.value.id,
              filename: request.file.name,
            },
          });
          const parsedFields = JSON.parse(fields);
          const formData = new FormData();
          Object.keys(parsedFields).forEach((key) => formData.append(key, parsedFields[key]));
          formData.append('file', request.file);
          const response = await fetch(url, { method: 'POST', body: formData });
          if (!response.ok) throw new Error();
          await createPriceListUpdate({ filePath, businessId: currentTenant.value.id });
          await refetch();
        } catch (err) {
          console.error(err);
          error();
        } finally {
          uploadLoading.close();
        }
      },
      routeToUpdate(priceListUpdateId) {
        root.$router.push({ name: 'priceListsManagement.priceListUpdate', params: { id: priceListUpdateId } });
      },
      onPriceListChange(priceListUpdate, priceListId) {
        const updateFields = ['name', 'description', 'updateDate', 'changes'];
        updatePriceListUpdate({
          id: priceListUpdate.id,
          updateParams: { ...pick(updateFields, priceListUpdate), priceListId },
        });
      },
      getCellClass(rowIndex, columnIndex) {
        const classes = [];
        if (rowIndex === selectedRowIndex.value) classes.push('highlight');
        if (columnIndex && ['priceListId', 'recordAction'].includes(this.columns[columnIndex - 1].key))
          classes.push('py-2');
        return classes.join(' ');
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.cell-with-subtext {
  position: relative;
  small {
    position: absolute;
    bottom: -1.5em;
    left: 0;
    right: 0;
  }
}

::v-deep {
  .el-upload,
  .el-upload-dragger {
    height: unset;
    width: 100%;
  }

  .highlight {
    background: $light-gray;
  }
}
</style>
