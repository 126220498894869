<template>
  <div>
    <Table
      v-loading="loading"
      border
      rounded
      :data="productsInCurrentPage"
      :columns="columns"
      show-index
      :hover="false"
    >
      <template #cell-price="{ rowData: { price } }">
        {{ formatMoney(price) }}
      </template>
      <template #cell-uom="{ rowData: { uom } }">
        {{ uom ? $t(`product.units.${uom}`) : '-' }}
      </template>
      <template #cell-priceChange="{ rowData: { price, priceChange, productId }, rowIndex }">
        <InputMoney
          v-if="editPrice.index === rowIndex"
          ref="moneyInput"
          v-model="editPrice.value"
          type="submit"
          style="width: 10em"
          @keyup.native.enter="priceUpdate(editPrice.value, productId)"
          @blur="priceUpdate(editPrice.value, productId)"
        />
        <div v-else class="d-flex justify-content-between" :class="priceChange ? 'text-primary' : 'text-muted'">
          <div v-if="priceChange && priceChange.price === null" class="text-primary">
            <EyeIcon /> {{ $t('price.priceListTable.tableMessages.hiddenInPriceList') }}
          </div>
          <p v-else>
            {{ formatMoney(priceChange ? priceChange.price : price) }}
          </p>
        </div>
      </template>
      <template #cell-kebab="{ rowData: { priceChange, productId } }">
        <el-dropdown trigger="click" placement="left" @command="(action) => handleAction(action, productId)">
          <Button type="text" class="p-0 text-typography-primary" @click.stop>
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item :command="'setPrice'">{{
              $t('price.priceListTable.editMode.setPrice')
            }}</el-dropdown-item>
            <el-dropdown-item :command="'hidePrice'" :disabled="priceChange && priceChange.price === null">{{
              $t('price.priceListTable.editMode.hideFromPriceList')
            }}</el-dropdown-item>
            <el-dropdown-item :command="'reset'" :disabled="!priceChange" divided>{{
              $t('price.priceListTable.editMode.reset')
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <div class="text-center mt-2">
      <el-pagination
        layout="prev, pager, next"
        small
        background
        :total="tableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
      />
      <div :style="{ width: '83px' }"></div>
    </div>
  </div>
</template>
<script>
import { Table, InputMoney, Button } from '@/modules/core';
import { options } from '@/locale/dateConfig';
import { currencyFloat } from '@/locale/numberConfig';
import { EyeIcon, KebabIcon } from '@/assets/icons';

const PRODUCT_TABLE_HEADERS = {
  GTIN: 'gtin',
  SKU: 'sku',
  PRODUCT_NAME: 'productName',
  UOM: 'uom',
  PRICE: 'price',
  PRICE_CHANGE: 'priceChange',
};

export default {
  components: { Table, EyeIcon, InputMoney, KebabIcon, Button },
  props: {
    priceListUpdate: { type: Object, required: true },
    products: { type: Array, default: () => [] },
    sectionProductsIds: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  data() {
    return { editPrice: { index: null, value: null }, pageSize: 20, currentPageIndex: 0 };
  },
  computed: {
    currentPage: {
      get() {
        return this.currentPageIndex + 1;
      },
      set(index) {
        this.currentPageIndex = index - 1;
      },
    },
    pageStartingIndex() {
      return this.currentPageIndex * this.pageSize;
    },
    productsInCurrentPage() {
      return this.tableData.slice(
        this.currentPageIndex * this.pageSize,
        this.currentPageIndex * this.pageSize + this.pageSize
      );
    },
    columns() {
      return [
        {
          header: this.$t('price.priceListTable.headers.gtin'),
          key: PRODUCT_TABLE_HEADERS.GTIN,
          width: '12%',
        },
        {
          header: this.$t('price.priceListTable.headers.sku'),
          key: PRODUCT_TABLE_HEADERS.SKU,
          width: '12%',
        },
        {
          header: this.$t('price.priceListTable.headers.productName'),
          key: PRODUCT_TABLE_HEADERS.PRODUCT_NAME,
          width: '25%',
        },
        {
          header: this.$t('price.priceListTable.headers.uom'),
          key: PRODUCT_TABLE_HEADERS.UOM,
          width: '10%',
        },
        {
          header: this.$t('price.priceListTable.headers.currentPrice'),
          key: PRODUCT_TABLE_HEADERS.PRICE,
          width: '12%',
        },
        {
          header:
            this.$t('price.priceListTable.headers.updatedAt') +
            new Date(this.priceListUpdate.updateDate).toLocaleDateString(this.$i18n.locale, options.short),
          key: PRODUCT_TABLE_HEADERS.PRICE_CHANGE,
          width: '17%',
        },
        {
          header: '',
          width: '60px',
          key: 'kebab',
        },
      ];
    },
    tableData() {
      return this.sectionProductsIds.map((productId) => {
        const price = this.priceListUpdate.priceList.prices.find((price) => price.productId === productId);
        const product = this.products.find(({ id }) => id === productId);
        const currentPrice = price?.data.reduce((last, price) => (price.date > last.date ? price : last)).price;
        const priceChange = (this.priceListUpdate.changes || []).find((change) => change.productId === productId);

        return {
          [PRODUCT_TABLE_HEADERS.GTIN]: product.gtin || '-',
          [PRODUCT_TABLE_HEADERS.SKU]: product.references[0].sku || '-',
          [PRODUCT_TABLE_HEADERS.PRODUCT_NAME]: product.references[0].name,
          [PRODUCT_TABLE_HEADERS.UOM]: product.uom,
          [PRODUCT_TABLE_HEADERS.PRICE]: currentPrice,
          [PRODUCT_TABLE_HEADERS.PRICE_CHANGE]: priceChange,
          productId: product.id,
        };
      });
    },
  },
  methods: {
    formatMoney(value) {
      return typeof value === 'number' ? Number(value / 100).toLocaleString(this.$i18n.locale, currencyFloat) : '-';
    },
    priceUpdate(price, productId) {
      if (price) this.$emit('price-update', { productId, price });
      this.editPrice.index = null;
    },
    handleAction(value, productId) {
      switch (value) {
        case 'setPrice':
          this.editPrice.index = this.tableData.findIndex((rowData) => rowData.productId === productId);
          this.editPrice.value = null;
          this.$nextTick(() => this.$refs.moneyInput.$children[0].focus());

          break;
        case 'hidePrice':
          this.editPrice.index = null;
          this.$emit('price-update', { productId, price: null });
          break;
        case 'reset':
          this.editPrice.index = null;
          this.$emit('price-reset', productId);
          break;
        default:
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .narrow-table {
  table {
    th,
    td {
      padding: 0.7em;
    }
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  outline: none;
}
</style>
