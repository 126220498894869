<template>
  <div>
    <div class="d-flex justify-content-between pb-4 align-items-center">
      <h3>{{ $t('price.PriceListsManagement.priceLists') }}</h3>
      <Button type="primary" @click="addingPriceList = true">
        <PlusIcon />
        {{ $t('price.PriceListsManagement.priceListsTable.newPriceList') }}
      </Button>
    </div>
    <Table
      v-loading="loading"
      border
      rounded
      :data="tableData"
      :columns="columns"
      show-index
      @row-click="routeToPriceList"
    >
      <template
        #cell-lastUpdate="{
          rowData: {
            lastUpdate: { totalChanges, updateDate },
          },
        }"
      >
        {{
          totalChanges
            ? $tc('price.PriceListsManagement.priceListsTable.lastUpdateDescription', totalChanges, {
                count: totalChanges,
                date: updateDate,
              })
            : '-'
        }}
      </template>
      <template #cell-kebab="{ rowData: { priceListId } }">
        <el-dropdown trigger="click" placement="left" @command="handlePriceListUpdate(priceListId)">
          <Button type="text" class="p-0 text-typography-primary" @click.stop>
            <KebabIcon />
          </Button>
          <el-dropdown-menu>
            <el-dropdown-item>{{ $t('price.PriceListsManagement.priceListsTable.infoAndSettings') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </Table>
    <AddPriceList v-if="addingPriceList" @closed="addingPriceList = false" @submit="handleAddPriceList" />
    <UpdatePriceList
      v-if="updatingPriceList"
      :update-price-list-object="updatePriceListObject"
      @closed="updatingPriceList = false"
      @submit="handleUpdatePriceList"
    />
  </div>
</template>
<script>
import { ref, reactive, computed, getCurrentInstance } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { omit, pick } from 'ramda';

import { Table, Button } from '@/modules/core';
import { options } from '@/locale/dateConfig';
import { KebabIcon, PlusIcon } from '@/assets/icons';
import { useTenancy } from '@/modules/auth';

import AddPriceList from './AddPriceList';
import UpdatePriceList from './UpdatePriceList';
import { CREATE_PRICE_LIST_MUTATION, PATCH_PRICE_LIST_MUTATION } from '../compositions';

const PRICE_LISTS_TABLE_HEADERS = {
  PRICE_LIST_NAME: 'priceListName',
  PRODUCT_COUNT: 'productCount',
  LAST_UPDATE: 'lastUpdate',
  LAST_UPDATE_NAME: 'lastUpdateName',
};

export default {
  components: { Table, KebabIcon, Button, PlusIcon, AddPriceList, UpdatePriceList },
  props: {
    priceLists: { type: Array, default: () => [] },
    priceListsLoading: { type: Boolean, default: false },
    refetch: { type: Function, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();

    const formatDate = (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, options.short);
    const { mutate: createPriceList, loading: createPriceListLoading } = useMutation(CREATE_PRICE_LIST_MUTATION);
    const { mutate: patchPriceList, loading: patchPriceListLoading } = useMutation(PATCH_PRICE_LIST_MUTATION);

    return {
      loading: computed(() => props.priceListsLoading || createPriceListLoading.value || patchPriceListLoading.value),
      formatDate,
      addingPriceList: ref(false),
      updatingPriceList: ref(false),
      createPriceList,
      patchPriceList,
      updatePriceListObject: reactive({}),
      currentTenant,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('price.PriceListsManagement.priceListsTable.headers.priceListName'),
          key: PRICE_LISTS_TABLE_HEADERS.PRICE_LIST_NAME,
          width: '25%',
        },
        {
          header: this.$t('price.PriceListsManagement.priceListsTable.headers.productCount'),
          key: PRICE_LISTS_TABLE_HEADERS.PRODUCT_COUNT,
          width: '20%',
        },
        {
          header: this.$t('price.PriceListsManagement.priceListsTable.headers.lastUpdate'),
          key: PRICE_LISTS_TABLE_HEADERS.LAST_UPDATE,
          width: '25%',
        },
        {
          header: this.$t('price.PriceListsManagement.priceListsTable.headers.lastUpdateName'),
          key: PRICE_LISTS_TABLE_HEADERS.LAST_UPDATE_NAME,
          width: '20%',
        },
        {
          header: '',
          width: '60px',
          key: 'kebab',
        },
      ];
    },
    tableData() {
      return this.priceLists.map((priceList) => ({
        [PRICE_LISTS_TABLE_HEADERS.PRICE_LIST_NAME]: priceList.name,
        [PRICE_LISTS_TABLE_HEADERS.PRODUCT_COUNT]: priceList.productCount,
        [PRICE_LISTS_TABLE_HEADERS.LAST_UPDATE]: {
          totalChanges: priceList.lastUpdate?.totalChanges,
          updateDate: priceList.lastUpdate ? this.formatDate(priceList.lastUpdate.updateDate) : null,
        },
        [PRICE_LISTS_TABLE_HEADERS.LAST_UPDATE_NAME]: priceList.lastUpdate?.name ?? '-',
        priceListId: priceList.id,
      }));
    },
  },
  methods: {
    async handleAddPriceList(newPriceList) {
      try {
        await this.createPriceList({
          createParams: {
            ...newPriceList,
            businessId: this.currentTenant.id,
          },
        });
        this.addingPriceList = false;
      } catch {
        this.$message.error(this.$t('errors.action'));
      }
      this.refetch();
    },
    handlePriceListUpdate(priceListId) {
      this.updatingPriceList = true;
      const priceList = this.priceLists.find((priceList) => priceList.id === priceListId);
      this.updatePriceListObject = pick(['id', 'name', 'description'], priceList);
    },
    async handleUpdatePriceList(updatedUpdatePriceListObject) {
      try {
        await this.patchPriceList({
          id: updatedUpdatePriceListObject.id,
          patchParams: {
            ...omit(['id'], updatedUpdatePriceListObject),
          },
        });
        this.updatingPriceList = false;
      } catch {
        this.$message.error(this.$t('errors.action'));
      }
      this.refetch();
    },
    routeToPriceList(rowIndex) {
      this.$router.push({ name: 'priceList', params: { priceListId: this.tableData[rowIndex].priceListId } });
    },
  },
};
</script>
